import SectionP from "../Common/SectionP"
import {
  Card,
  Grid,
  Typography,
  CardActionArea,
  makeStyles,
} from "@material-ui/core"
import React from "react"
import courses from "../../imgs/courses.svg"
import work from "../../imgs/work.svg"
import team from "../../imgs/team.svg"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  section1: {
    backgroundColor: theme.palette.secondary[500],
  },
  section2: {
    backgroundColor: theme.palette.secondary.surface,
  },
  linkCard: {
    maxWidth: 400,
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(3),
    },
  },
  linkImageContainer: {},
  image: {
    height: 180,
    [theme.breakpoints.only("sm")]: {
      height: 150,
    },
  },
}))

export default function LearnMoreAboutUs() {
  const classes = useStyles()

  return (
    <>
      <SectionP moreClasses={classes.section1}>
        <LearnMoreHeading/>
      </SectionP>
      <SectionP moreClasses={classes.section2}>
        <LearnMoreLinks classes={classes} />
      </SectionP>
    </>
  )
}

const LearnMoreHeading = ()  => (
  <Grid container alignItems="center" direction={"column"}>
    <Grid item xs={10}>
      <Typography variant={"h5"} paragraph align={"center"}>
        {"If you want to learn more about us or our work,".toUpperCase()}
      </Typography>
    </Grid>
    <Grid item xs={10}>
      <Typography variant={"h5"}>
        {"these links will guide you".toUpperCase()}
      </Typography>
    </Grid>
  </Grid>
)

const LearnMoreLinks = ({ classes }) => {
  const data = [
    {
      name: "BLOG",
      img: courses,
      linkTo: '/blog'
    },
    {
      name: "TEAM",
      img: team,
      linkTo: '/team'
    },
    {
      name: "COURSES",
      img: work,
      linkTo: '/courses'
    },
  ]
  return (
    <Grid container justify={"space-evenly"}>
      {data.map((item, key) => (
        <Grid item xs={10} sm={3} xl={2} key={key}>
          <LinkCard photo={item.img} title={item.name} linkTo={item.linkTo} classes={classes} />
        </Grid>
      ))}
    </Grid>
  )
}

const LinkCard = ({ photo, title, linkTo, classes }) => {
  return (
    <Card className={classes.linkCard} onClick={() => navigate(linkTo)}>
      <CardActionArea>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <img src={photo} alt={title} className={classes.image} loading={"lazy"}/>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom align={"center"} variant={"h6"}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"
import { About } from "../components/About"

export default class TeamContainer extends React.Component {
  render() {
    const seo = seoData.aboutPage
    return (
      <>
        <SEO
          title={seo.title}
          description={seo.metaDescription}
          pathname={seo.pathname}
        />
        <Layout selectedTabValue={4}>
          <About />
        </Layout>
      </>
    )
  }
}

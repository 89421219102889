import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { OurStoryData } from "../../shared/about-data"
import SectionP from "../Common/SectionP"
import SectionTitle from "../Common/SectionTitle"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: theme.palette.secondary.surface,
  },
  image: {
    marginTop: theme.spacing(4),
  },
}))

export const OurStory = () => {
  const classes = useStyles()
  const data = useStaticQuery(query)
  const teamImage = data.allFile.edges.filter(edge => edge.node.name === "team")[0]

  return (
    <SectionP moreClasses={classes.section}>
      <Grid container justify="center">
        <Grid item xs={10}>
          <Grid container justify={"center"}>
            <Grid item xs={12}>
              <SectionTitle color="textPrimary">OUR STORY</SectionTitle>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column">
                <Grid item>
                  {OurStoryData.map((data, key) => (
                    <Typography paragraph variant="subtitle1" key={key}>
                      {data}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify={"center"}>
        <Grid item xs={12} lg={7} className={classes.image}>
        <Img fluid={teamImage.node.childImageSharp.fluid} loading={"lazy"}
             imgStyle={{
               objectFit: "contain",
             }}
        />
        </Grid>
      </Grid>
    </SectionP>
  )
}

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { eq: "jpeg" }
      }
    ) {
      edges {
        node {
          name
          sourceInstanceName
          childImageSharp {
            fluid {
              src
              sizes
              srcSet
              base64
              aspectRatio
            }
          }
        }
      }
    }
  }
`

import { Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import SectionP from "../Common/SectionP"
import SectionTitle from "../Common/SectionTitle"
import { MissionConclusion, MissionData } from "../../shared/about-data"
import Visualize from "../../imgs/about/mission/visualize.svg"
import Break from "../../imgs/about/mission/break.svg"
import Solve from "../../imgs/about/mission/solve.svg"

export const Mission = () => (
  <SectionP>
    <Grid container justify="center">
      <SectionTitle color="textPrimary">OUR MISSION</SectionTitle>
      <Grid container direction="column" xs={10}>
        {MissionData.map(data => (
          <Typography paragraph variant="subtitle1">
            {data}
          </Typography>
        ))}
      </Grid>
      <MissionImages />
      <Grid container direction="column" xs={10}>
        <Typography paragraph variant="subtitle1">
          {MissionConclusion}
        </Typography>
      </Grid>
    </Grid>
  </SectionP>
)

export const MissionImages = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      justify={"center"}
      alignItems={"center"}
      className={classes.imageContainerPadding}
    >
      <Grid
        container
        justify="center"
        item
        xs={10}
        md={4}
        className={classes.mobileImages}
      >
        <img src={Visualize} alt={"visualize"} loading={"lazy"}/>
      </Grid>
      <Grid
        container
        justify="center"
        item
        xs={10}
        md={4}
        className={classes.mobileImages}
      >
        <img src={Break} alt={"break"} loading={"lazy"}/>
      </Grid>
      <Grid
        container
        justify="center"
        item
        xs={10}
        md={4}
        className={classes.mobileImages}
      >
        <img src={Solve} alt={"solve"} loading={"lazy"} />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  imageContainerPadding: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
  mobileImages: {
    [theme.breakpoints.between("xs", "sm")]: {
      border: `1px solid ${theme.palette.secondary[800]}`,
      marginBottom: theme.spacing(2),
      borderRadius: "25px",
    },
  },
}))

export const MissionData = [
 'Our mission is to help and educate those interested in technology and software development. ' +
 'We enable learners to understand and retain their programming knowledge through our high-quality digital content and mentoring program. We strongly believe that "visual memories are the strongest memories," and we do not easily forget what we see. Our approach is to educate using stories, visual language, and concrete examples from daily lives. ' +
 'We teach how to visualize a "complex" problem, break it into fundamental parts, and solve it as a composition of atomic pieces.'
]

export const MissionConclusion = 'As a result, a "complex" no longer remains complex.';

export const OurStoryData = [
  'We (Deeksha and Harit) are a couple who first met in 2003 during our undergraduate studies. We both went on to pursue our graduate degrees in Computer Science from California and New York. With a combined work experience of 20+ years as full time and remote engineers, we learned how to create and ship quality software products and mentor teams. Since 2007, we have explored, worked, and lived in beautiful places worldwide, including Bangalore, New York, Toronto, San Francisco, Auckland, Montreal, and Vancouver Island.',
  'We are obsessed with acquiring new skills that serve us as well as others. This continuous learning has added new tools in our arsenals, such as UX design, doodling, speed reading, video making, and writing. Using these skills, we started sharing our programming knowledge with co-workers and learners worldwide. Our approach to problem-solving using visuals stories supplemented with relatable examples from day to day life witnessed the higher engagement. People around us found this methodology highly actionable and relevant in their work.',
  'Therefore, we committed ourselves to hone this skill and serve a broader community of learners, which gave birth to bonsaiilabs.'
]


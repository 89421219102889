import { Grid } from "@material-ui/core"
import * as React from "react"
import { PageTitles } from "../../shared/constants"
import PageTitleDescription from "../Common/PageTitleDescription"
import { Mission } from "./Mission"
import { OurStory } from "./OurStory"
import Praises from "../Praises"
import LearnMoreAboutUs from "./LearnMoreAboutUs"
import { getReviews } from "../../shared/reviews-data"

export const About = () => (
  <Grid container justify="center">
    <Grid item xs={12}>
      <PageTitleDescription
        title={PageTitles.about.title}
        description={PageTitles.about.description}
      />
    </Grid>
    <Grid item xs={12}>
      <Mission />
    </Grid>
    <Grid item xs={12}>
      <OurStory />
    </Grid>
    <Grid item xs={12}>
      <Praises data={getReviews()} />
    </Grid>
    <Grid item xs={12}>
      <LearnMoreAboutUs />
    </Grid>
  </Grid>
)

